<template>
  <v-container class="pa-4">
    <Substipulator
      :contractId="$route.params.id"
      @sub="subSelected($event)"
      v-if="hasSub"
    />

    <v-form ref="form" lazy-validation v-model="valid">
      <v-row dense>
        <template v-if="productType === 'Seguro de vida'">
          <v-col
            cols="12"
            :xl="columnName"
            :lg="columnName"
            :md="columnName"
            sm="6"
          >
            <v-text-field
              id="name"
              name="name"
              label="Nome"
              maxlength="20"
              dense
              outlined
              required
              :rules="[required]"
              :disabled="isEditable"
              v-model="coverage.name"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <v-autocomplete
              id="coverageRateType"
              name="coverageRateType"
              label="Tipo de taxa"
              dense
              outlined
              required
              :rules="[required]"
              :items="['Apólice', 'Cobertura']"
              :disabled="isEditable"
              v-model="coverage.coverageRateType"
            />
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="6"
            v-if="coverage.coverageRateType === 'Cobertura'"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          >
            <BaseAutonumeric
              id="coverageRate"
              name="coverageRate"
              label="Taxa de cobertura"
              suffix="%"
              outlined
              required
              :rules="[required]"
              :options="{ allowDecimalPadding: 'floats', decimalPlaces: '10' }"
              v-model="coverage.coverageRate"
            />
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="6"
            v-if="coverage.coverageRateType === 'Apólice'"
          >
            <BaseAutonumeric
              id="coveragePolicyRate"
              name="coveragePolicyRate"
              label="Taxa de apólice"
              suffix="%"
              outlined
              required
              :rules="[required]"
              :disabled="isEditable"
              :options="{ allowDecimalPadding: 'floats', decimalPlaces: '10' }"
              v-model="coverage.coveragePolicyRate"
            />
          </v-col>
        </template>

        <template v-if="contract.situation !== 'Cancelado'">
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          >
            <BaseCoverages
              id="coverageId"
              name="coverageId"
              label="Cobertura"
              required
              :rules="[required]"
              :contractId="$route.params.id"
              v-model="coverage.coverageId"
            />
          </v-col>
        </template>

        <template
          v-if="
            productType === 'Seguro de vida' &&
            contract.situation !== 'Cancelado'
          "
        >
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="6"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          >
            <BaseNumber
              id="coveragePercentage"
              name="coveragePercentage"
              label="Percentual"
              maxlength="3"
              suffix="%"
              required
              :rules="[required]"
              v-model="coverage.coveragePercentage"
            />
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="6"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          >
            <BaseMoney
              id="coverageValueLimit"
              name="coverageValueLimit"
              label="Valor limite"
              v-model="coverage.coverageValueLimit"
            />
          </v-col>
        </template>

        <template v-if="contract.situation !== 'Cancelado'">
          <v-col
            cols="12"
            :xl="columnAction"
            :lg="columnAction"
            :md="columnAction"
            sm="12"
            :class="productType === 'Seguro de vida' && 'text-right'"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          >
            <BaseButton
              id="btn-cancel"
              name="btn-cancel"
              color="secondary"
              outlined
              title="Cancelar"
              height="40"
              v-if="isEditCoverage"
              @click="cancelCoverage"
            />
            <BaseButton
              id="btn-add"
              name="btn-add"
              color="secondary"
              title="Adicionar"
              height="40"
              v-if="!isEditCoverage"
              @click="addCoverage"
            />
            <BaseButton
              id="btn-save"
              name="btn-save"
              color="secondary"
              title="Atualizar"
              height="40"
              v-if="isEditCoverage"
              @click="changeCoverage"
            />
          </v-col>
        </template>
      </v-row>
    </v-form>

    <BaseTableList
      title="Listagem de coberturas"
      :class="
        productType === 'Seguro de vida' &&
        contract.situation !== 'Cancelado' &&
        'pt-5'
      "
    >
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="filteredHeaders"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.coveragePercentage`]="{ item }">
            {{ formatPercentage(item.coveragePercentage) }}
          </template>

          <template v-slot:[`item.coverageValueLimit`]="{ item }">
            {{ formatMoney(item.coverageValueLimit) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip
              bottom
              v-if="
                productType === 'Seguro de vida' &&
                contract.situation !== 'Cancelado'
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="editCoverage(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom v-if="contract.situation !== 'Cancelado'">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="remove(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <div class="d-flex">
      <v-spacer />
      <BaseButton
        id="btn-back"
        name="btn-back"
        title="Voltar"
        color="primary"
        outlined
        @click="back"
      />

      <template v-if="productType === 'Seguro de vida'">
        <BaseButton
          outlined
          id="btn-view-history"
          name="btn-view-history"
          color="error darken-1"
          title="Visualizar histórico"
          @click="history(true)"
          v-if="items.length && canViewHistory"
        />

        <template v-if="contract.situation !== 'Cancelado'">
          <BaseButton
            id="btn-history"
            name="btn-history"
            color="primary"
            title="Aplicar reajuste"
            @click="history(false)"
            v-if="items.length"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          />
        </template>
      </template>
    </div>

    <DialogCoverageHistory
      v-if="dialog"
      :dialog="dialog"
      :title="dialogTitle"
      :history="dialogModeHistory"
      :customerId="substipulator ? substipulator : customerId"
      :contractId="$route.params.id"
      :coverage="coverage"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { rulesMixin } from '@/mixins/rules';
import { formatMoney, formatPercentage } from '@/helpers/formatting';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    Substipulator: () => import('./commons/substipulator'),
    DialogCoverageHistory: () => import('./dialogs/dialog-coverage')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    substipulator: null,
    canViewHistory: false,
    isEditable: false,
    isEditCoverage: false,
    valid: true,
    options: {},
    coverage: {
      name: null,
      coverageId: null,
      coveragePolicyRate: null,
      coveragePercentage: null,
      coverageValueLimit: null,
      coverageRateType: null,
      coverageRate: null
    },
    dialog: false,
    dialogModeHistory: false,
    dialogTitle: ''
  }),

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    }),

    filteredHeaders() {
      let headers = [
        { text: 'Coberturas', value: 'coverageName' },
        { text: 'Percentual', value: 'coveragePercentage' },
        { text: 'Valor limite', value: 'coverageValueLimit' },
        { text: '', value: 'action' }
      ];

      if (this.productType !== 'Seguro de vida') {
        headers = headers.filter(
          header =>
            header.value !== 'coveragePercentage' &&
            header.value !== 'coverageValueLimit'
        );
      }

      return headers;
    },

    columnName() {
      return this.coverage.coverageRateType ? 6 : 9;
    },

    columnAction() {
      return this.productType === 'Seguro de vida' ? 12 : 4;
    }
  },

  methods: {
    formatMoney,
    formatPercentage,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const service = new ContractService();
        const { status, data } =
          await service.getCoveragesByContractIdByCustomerId({
            page,
            take: itemsPerPage,
            contractId: this.$route.params.id,
            customerId: this.substipulator
              ? this.substipulator
              : this.customerId
          });

        if (status === 200) {
          this.defaultFieldsValue(data.results);
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async addCoverage() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const contractService = new ContractService();
        const { status } = await contractService.createCoverage({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId,
          ...this.coverage
        });

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async changeCoverage() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const contractService = new ContractService();
        const { status } = await contractService.updateCoverage({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId,
          ...this.coverage
        });

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
          this.cancelCoverage();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async remove(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const contractService = new ContractService();
          const { status } = await contractService.deleteCoverage({
            contractId: this.$route.params.id,
            customerId: this.substipulator
              ? this.substipulator
              : this.customerId,
            ...item
          });

          if (status === 200 || status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    back() {
      this.$emit('back');
    },

    editCoverage(item) {
      this.isEditCoverage = true;
      this.coverage = Object.assign({}, item);
    },

    defaultFieldsValue(result) {
      if (result.length > 0) {
        this.isEditable = true;
        this.canViewHistory = result[0].hasReadjustmentHistory;
        this.coverage.coveragePolicyRate = result[0].coveragePolicyRate;
        this.coverage.coverageRateType = result[0].coverageRateType;
        this.coverage.name = result[0].name;
      } else {
        this.isEditable = false;
        this.canViewHistory = false;
        this.coverage.coveragePolicyRate = null;
        this.coverage.coverageRateType = null;
        this.coverage.name = null;
      }

      setTimeout(() => {
        this.$refs.form.resetValidation();
      }, 200);
    },

    cancelCoverage() {
      this.$refs.form.reset();
      this.isEditCoverage = false;

      setTimeout(() => {
        this.defaultFieldsValue(this.items);
      }, 100);
    },

    subSelected(event) {
      this.substipulator = event;
      this.search();
    },

    history(value) {
      this.dialog = true;

      if (value) {
        this.dialogTitle = 'Visualizar histórico';
        this.dialogModeHistory = true;
      } else {
        if (this.items.length === 0) {
          showMessage(
            'Error',
            `Para reajustar os valores das coberturas, é preciso ter coberturas cadastrados!`
          );
          return;
        }

        this.dialogTitle = 'Reajustar percentual';
        this.dialogModeHistory = false;
      }
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogTitle = '';
      this.dialogModeHistory = false;

      if (search) {
        this.search();
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
