var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-4"},[(_vm.hasSub)?_c('Substipulator',{attrs:{"contractId":_vm.$route.params.id},on:{"sub":function($event){return _vm.subSelected($event)}}}):_vm._e(),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[(_vm.productType === 'Seguro de vida')?[_c('v-col',{attrs:{"cols":"12","xl":_vm.columnName,"lg":_vm.columnName,"md":_vm.columnName,"sm":"6"}},[_c('v-text-field',{attrs:{"id":"name","name":"name","label":"Nome","maxlength":"20","dense":"","outlined":"","required":"","rules":[_vm.required],"disabled":_vm.isEditable},model:{value:(_vm.coverage.name),callback:function ($$v) {_vm.$set(_vm.coverage, "name", $$v)},expression:"coverage.name"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"id":"coverageRateType","name":"coverageRateType","label":"Tipo de taxa","dense":"","outlined":"","required":"","rules":[_vm.required],"items":['Apólice', 'Cobertura'],"disabled":_vm.isEditable},model:{value:(_vm.coverage.coverageRateType),callback:function ($$v) {_vm.$set(_vm.coverage, "coverageRateType", $$v)},expression:"coverage.coverageRateType"}})],1),(_vm.coverage.coverageRateType === 'Cobertura')?_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }),expression:"{\n            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n            operator: '==='\n          }"}],attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('BaseAutonumeric',{attrs:{"id":"coverageRate","name":"coverageRate","label":"Taxa de cobertura","suffix":"%","outlined":"","required":"","rules":[_vm.required],"options":{ allowDecimalPadding: 'floats', decimalPlaces: '10' }},model:{value:(_vm.coverage.coverageRate),callback:function ($$v) {_vm.$set(_vm.coverage, "coverageRate", $$v)},expression:"coverage.coverageRate"}})],1):_vm._e(),(_vm.coverage.coverageRateType === 'Apólice')?_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('BaseAutonumeric',{attrs:{"id":"coveragePolicyRate","name":"coveragePolicyRate","label":"Taxa de apólice","suffix":"%","outlined":"","required":"","rules":[_vm.required],"disabled":_vm.isEditable,"options":{ allowDecimalPadding: 'floats', decimalPlaces: '10' }},model:{value:(_vm.coverage.coveragePolicyRate),callback:function ($$v) {_vm.$set(_vm.coverage, "coveragePolicyRate", $$v)},expression:"coverage.coveragePolicyRate"}})],1):_vm._e()]:_vm._e(),(_vm.contract.situation !== 'Cancelado')?[_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }),expression:"{\n            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n            operator: '==='\n          }"}],attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('BaseCoverages',{attrs:{"id":"coverageId","name":"coverageId","label":"Cobertura","required":"","rules":[_vm.required],"contractId":_vm.$route.params.id},model:{value:(_vm.coverage.coverageId),callback:function ($$v) {_vm.$set(_vm.coverage, "coverageId", $$v)},expression:"coverage.coverageId"}})],1)]:_vm._e(),(
          _vm.productType === 'Seguro de vida' &&
          _vm.contract.situation !== 'Cancelado'
        )?[_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }),expression:"{\n            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n            operator: '==='\n          }"}],attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('BaseNumber',{attrs:{"id":"coveragePercentage","name":"coveragePercentage","label":"Percentual","maxlength":"3","suffix":"%","required":"","rules":[_vm.required]},model:{value:(_vm.coverage.coveragePercentage),callback:function ($$v) {_vm.$set(_vm.coverage, "coveragePercentage", $$v)},expression:"coverage.coveragePercentage"}})],1),_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }),expression:"{\n            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n            operator: '==='\n          }"}],attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('BaseMoney',{attrs:{"id":"coverageValueLimit","name":"coverageValueLimit","label":"Valor limite"},model:{value:(_vm.coverage.coverageValueLimit),callback:function ($$v) {_vm.$set(_vm.coverage, "coverageValueLimit", $$v)},expression:"coverage.coverageValueLimit"}})],1)]:_vm._e(),(_vm.contract.situation !== 'Cancelado')?[_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }),expression:"{\n            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n            operator: '==='\n          }"}],class:_vm.productType === 'Seguro de vida' && 'text-right',attrs:{"cols":"12","xl":_vm.columnAction,"lg":_vm.columnAction,"md":_vm.columnAction,"sm":"12"}},[(_vm.isEditCoverage)?_c('BaseButton',{attrs:{"id":"btn-cancel","name":"btn-cancel","color":"secondary","outlined":"","title":"Cancelar","height":"40"},on:{"click":_vm.cancelCoverage}}):_vm._e(),(!_vm.isEditCoverage)?_c('BaseButton',{attrs:{"id":"btn-add","name":"btn-add","color":"secondary","title":"Adicionar","height":"40"},on:{"click":_vm.addCoverage}}):_vm._e(),(_vm.isEditCoverage)?_c('BaseButton',{attrs:{"id":"btn-save","name":"btn-save","color":"secondary","title":"Atualizar","height":"40"},on:{"click":_vm.changeCoverage}}):_vm._e()],1)]:_vm._e()],2)],1),_c('BaseTableList',{class:_vm.productType === 'Seguro de vida' &&
      _vm.contract.situation !== 'Cancelado' &&
      'pt-5',attrs:{"title":"Listagem de coberturas"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.filteredHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.coveragePercentage`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPercentage(item.coveragePercentage))+" ")]}},{key:`item.coverageValueLimit`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.coverageValueLimit))+" ")]}},{key:`item.action`,fn:function({ item }){return [(
              _vm.productType === 'Seguro de vida' &&
              _vm.contract.situation !== 'Cancelado'
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                  operator: '==='
                }),expression:"{\n                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n                  operator: '==='\n                }"}],staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.editCoverage(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]):_vm._e(),(_vm.contract.situation !== 'Cancelado')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                  operator: '==='
                }),expression:"{\n                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n                  operator: '==='\n                }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])]):_vm._e()]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('BaseButton',{attrs:{"id":"btn-back","name":"btn-back","title":"Voltar","color":"primary","outlined":""},on:{"click":_vm.back}}),(_vm.productType === 'Seguro de vida')?[(_vm.items.length && _vm.canViewHistory)?_c('BaseButton',{attrs:{"outlined":"","id":"btn-view-history","name":"btn-view-history","color":"error darken-1","title":"Visualizar histórico"},on:{"click":function($event){return _vm.history(true)}}}):_vm._e(),(_vm.contract.situation !== 'Cancelado')?[(_vm.items.length)?_c('BaseButton',{directives:[{name:"permission",rawName:"v-permission",value:({
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }),expression:"{\n            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n            operator: '==='\n          }"}],attrs:{"id":"btn-history","name":"btn-history","color":"primary","title":"Aplicar reajuste"},on:{"click":function($event){return _vm.history(false)}}}):_vm._e()]:_vm._e()]:_vm._e()],2),(_vm.dialog)?_c('DialogCoverageHistory',{attrs:{"dialog":_vm.dialog,"title":_vm.dialogTitle,"history":_vm.dialogModeHistory,"customerId":_vm.substipulator ? _vm.substipulator : _vm.customerId,"contractId":_vm.$route.params.id,"coverage":_vm.coverage},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }